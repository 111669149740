<template>
  <div>
    <restaurants-component />
    <footer-component />
  </div>
</template>

<script>
import RestaurantsComponent from "@/components/restaurants-leisures/RestaurantsComponent";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
  name: "Restaurants",
  title: "Restaurants | Baja California Health Tourism",
  components: {
    RestaurantsComponent,
    FooterComponent,
  },
  mounted() {
    changeInformationSEO();
  }
};
</script>

<style scoped></style>
